<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/light.jpg')"
      class="white--text"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading
            weight="regular"
            title="KSystems"
            align="center"
            size="text-h1"
          />
          <base-img
            :src="require('@/assets/hero_img.svg')"
            class="d-flex align-center mx-auto my-10"
            contain
            max-width="292"
            width="100%"
          />
          <base-subheading
            :title="$t('description_head')"
            weight="medium"
            size="text-h6"
            align="center"
          />
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-center' : 'align-center'"
            class="d-flex flex-wrap justify-center"
          >
<!--            <v-btn
              width="30%"
              min-height="60"
              class="btn_more mt-lg-9"
            >
              More
            </v-btn>-->
          </div>
          <!--          <base-body>-->
          <!--            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae nobis neque aspernatur in quas iure id aliquid, esse debitis, quibusdam mollitia! Quia ea expedita magnam totam, earum omnis et perspiciatis?-->
          <!--          </base-body>-->
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',
    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
<style lang="sass">
  .btn_more
    color: white !important
    border: 2px solid #0A8C4C
    background: transparent
    text-align: center
</style>
